// TODO: This icon us used as an illustration. Find a solution that also consider design configruation

interface Props {
  width?: number
  height?: number
}

const OnboardSvg = ({ width = 399, height = 206 }: Props) => {
  return (
    <svg
      fill="none"
      height={height}
      viewBox="0 0 399 206"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M65 129.476C52.1961 129.476 39.6797 125.679 29.0336 118.565C18.3875 111.452 10.0899 101.341 5.1901 89.5118C0.290257 77.6826 -0.991766 64.666 1.50615 52.1081C4.00407 39.5502 10.1697 28.015 19.2235 18.9613C28.2772 9.90754 39.8124 3.74187 52.3703 1.24395C64.9282 -1.25397 77.9448 0.02805 89.774 4.9279C101.603 9.82774 111.714 18.1253 118.827 28.7714C125.941 39.4175 129.738 51.9339 129.738 64.7378C129.711 81.8991 122.882 98.3501 110.747 110.485C98.6123 122.62 82.1614 129.449 65 129.476ZM65 10.1216C54.1781 10.1216 43.5992 13.3306 34.6012 19.3429C25.6031 25.3553 18.59 33.9008 14.4486 43.8989C10.3072 53.897 9.22367 64.8987 11.3349 75.5126C13.4462 86.1266 18.6574 95.8761 26.3096 103.528C33.9619 111.181 43.7114 116.392 54.3253 118.503C64.9393 120.614 75.9409 119.531 85.939 115.389C95.9372 111.248 104.483 104.235 110.495 95.2368C116.507 86.2387 119.716 75.6599 119.716 64.838C119.698 50.332 113.927 36.4254 103.67 26.1681C93.4125 15.9108 79.506 10.1401 65 10.1216Z"
          fill="#383A48"
        />
        <path
          d="M65 182.989C61.6691 182.994 58.3701 182.341 55.292 181.069C52.2138 179.796 49.417 177.929 47.0617 175.574C44.7065 173.218 42.8391 170.422 41.5666 167.343C40.2941 164.265 39.6414 160.966 39.646 157.635V116.849H90.3539V157.635C90.3462 164.357 87.6725 170.802 82.9194 175.555C78.1663 180.308 71.7219 182.982 65 182.989Z"
          fill="#FFD600"
        />
        <path
          d="M65 188C56.9504 187.988 49.2338 184.785 43.5418 179.094C37.8499 173.402 34.647 165.685 34.6354 157.635V111.838H95.3646V157.635C95.3529 165.685 92.1501 173.402 86.4581 179.094C80.7662 184.785 73.0496 187.988 65 188ZM44.6567 121.959V157.736C44.6567 163.131 46.8 168.305 50.6151 172.12C54.4302 175.936 59.6046 178.079 65 178.079C70.3954 178.079 75.5698 175.936 79.3849 172.12C83.2 168.305 85.3433 163.131 85.3433 157.736V121.959H44.6567Z"
          fill="#383A48"
        />
        <path
          d="M90.3539 133.985H39.646V144.006H90.3539V133.985Z"
          fill="#383A48"
        />
        <path
          d="M90.3539 156.032H39.646V166.053H90.3539V156.032Z"
          fill="#383A48"
        />
      </g>
      <g clipPath="url(#clip1)">
        <path
          d="M186.506 206C185.206 206 183.905 205.5 183.005 204.499L161.694 183.189C151.789 173.284 151.789 157.276 161.694 147.472C171.599 137.567 187.607 137.567 197.412 147.472L204.415 154.475C213.419 163.479 228.026 163.479 237.031 154.475L239.932 151.574C241.933 149.573 245.035 149.573 247.036 151.574C249.037 153.575 249.037 156.676 247.036 158.677L244.134 161.578C231.228 174.485 210.218 174.485 197.312 161.578L190.308 154.575C184.305 148.572 174.701 148.572 168.698 154.575C162.695 160.578 162.695 170.183 168.698 176.186L190.008 197.496C192.009 199.497 192.009 202.598 190.008 204.599C189.108 205.5 187.807 206 186.506 206Z"
          fill="#383A48"
        />
        <path
          d="M310.266 65.8319L295.159 50.7246L337.78 8.10392C341.982 3.90188 348.685 3.90188 352.887 8.10392C357.089 12.306 357.089 19.0092 352.887 23.2113L310.266 65.8319Z"
          fill="#F3F6E6"
        />
        <path
          d="M310.266 70.8344C308.966 70.8344 307.665 70.3341 306.765 69.3337L291.657 54.2263C290.757 53.3259 290.157 52.0253 290.157 50.7246C290.157 49.424 290.657 48.1234 291.657 47.2229L334.278 4.60223C337.279 1.60078 341.181 0 345.383 0C349.585 0 353.487 1.60078 356.489 4.60223C362.592 10.7052 362.592 20.61 356.489 26.713L313.868 69.3337C312.768 70.3341 311.467 70.8344 310.266 70.8344ZM302.262 50.7246L310.266 58.7285L349.385 19.6095C351.586 17.4085 351.586 13.8067 349.385 11.6056C348.285 10.5051 346.884 9.90481 345.383 9.90481C343.883 9.90481 342.482 10.5051 341.381 11.6056L302.262 50.7246Z"
          fill="#383A48"
        />
        <path
          d="M332.877 88.4429L347.985 103.55L390.605 60.9296C394.807 56.7275 394.807 50.0243 390.605 45.8222C386.403 41.6202 379.7 41.6202 375.498 45.8222L332.877 88.4429Z"
          fill="#F3F6E6"
        />
        <path
          d="M347.885 108.453C346.584 108.453 345.283 107.952 344.383 106.952L329.276 91.8446C328.375 90.9442 327.775 89.6435 327.775 88.3429C327.775 87.0423 328.275 85.7416 329.276 84.8412L371.896 42.2205C374.898 39.2191 378.8 37.6183 383.002 37.6183C387.204 37.6183 391.106 39.2191 394.107 42.2205C400.21 48.3235 400.21 58.2283 394.107 64.3313L351.486 106.952C350.486 107.952 349.185 108.453 347.885 108.453ZM339.881 88.443L347.885 96.4468L387.004 57.3279C389.205 55.1268 389.205 51.525 387.004 49.324C384.803 47.1229 381.201 47.1229 379 49.324L339.881 88.443Z"
          fill="#383A48"
        />
        <path
          d="M247.436 151.173C222.524 126.261 222.524 85.7416 247.436 60.8295L275.449 32.8159C282.053 26.2127 292.758 26.2127 299.461 32.8159L365.893 99.2482C372.497 105.851 372.497 116.557 365.893 123.26L337.88 151.273C312.868 176.085 272.448 176.085 247.436 151.173Z"
          fill="#FFD600"
        />
        <path
          d="M292.658 174.885C274.249 174.885 256.941 167.681 243.934 154.675C230.928 141.669 223.724 124.36 223.724 105.951C223.724 87.5425 230.928 70.2341 243.934 57.2278L271.948 29.2142C276.05 25.1122 281.653 22.8111 287.455 22.8111C293.258 22.8111 298.861 25.1122 302.963 29.2142L369.395 95.6465C373.497 99.7484 375.798 105.351 375.798 111.154C375.798 117.057 373.497 122.56 369.395 126.662L341.382 154.675C328.375 167.681 311.067 174.885 292.658 174.885ZM287.555 32.816C284.354 32.816 281.352 34.0165 279.151 36.3177L251.138 64.3313C228.127 87.3424 228.127 124.661 251.138 147.672C274.149 170.683 311.467 170.683 334.478 147.672L362.492 119.658C364.793 117.357 365.993 114.356 365.993 111.254C365.993 108.052 364.793 105.051 362.492 102.85L296.06 36.4177C293.758 34.0165 290.757 32.816 287.555 32.816Z"
          fill="#383A48"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect fill="white" height="188" width="130" />
        </clipPath>
        <clipPath id="clip1">
          <rect
            fill="white"
            height="206"
            transform="translate(154)"
            width="245"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default OnboardSvg
