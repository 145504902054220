import { Toast, ToastMessage } from './types'

let toastsCounter = 1

const DEFAULT_DURATION = 5000

class Observer {
  subscribers: any[]
  toasts: Array<Toast>

  constructor() {
    this.subscribers = []
    this.toasts = []
  }

  create = (data: Toast) => {
    const { title, duration = DEFAULT_DURATION, ...rest } = data
    const type = data.type || 'default'
    const id =
      typeof data?.id === 'number' || data.id?.length > 0
        ? data.id
        : toastsCounter++

    const alreadyExists = this.toasts.find((toast) => {
      return toast.id === id
    })

    if (alreadyExists) {
      this.toasts = this.toasts.map((toast) => {
        if (toast.id === id) {
          this.publish({ ...toast, ...data, title, duration, id, type })

          return { ...toast, ...data, id, title, duration, type }
        }

        return toast
      })
    } else {
      this.addToast({ title, ...rest, id, duration, type })
    }

    return id
  }

  addToast = (data: Toast) => {
    this.publish(data)
    this.toasts = [...this.toasts, data]
  }

  subscribe = (subscriber: (toast: Toast) => void) => {
    this.subscribers.push(subscriber)

    return () => {
      const index = this.subscribers.indexOf(subscriber)

      this.subscribers.splice(index, 1)
    }
  }

  publish = (data: Toast) => {
    this.subscribers.forEach((subscriber) => subscriber(data))
  }

  success = (message: ToastMessage, data?: Toast) => {
    return this.create({ ...message, ...data, type: 'success' })
  }

  error = (message: ToastMessage, data?: Toast) => {
    return this.create({ ...message, ...data, type: 'error' })
  }

  warning = (message: ToastMessage, data?: Toast) => {
    return this.create({ ...message, ...data, type: 'warning' })
  }

  info = (message: ToastMessage, data?: Toast) => {
    return this.create({ ...message, ...data, type: 'info' })
  }
}

export const toastDefaultFunction = (message: ToastMessage, data?: Toast) => {
  const id = data?.id || toastsCounter++
  const duration = message?.options?.duration || DEFAULT_DURATION

  ToastState.addToast({ ...message, duration, ...data, id })

  return id
}

export const ToastState = new Observer()
